

export const addItemInRecord = (oldRecord, newRecord) => {
    if(oldRecord){
        return [newRecord, ...oldRecord]
    }
    else{
        return [newRecord]
    }
    
}
export const addBadgeRecord = (oldRecord, newRecord, pathName) => {
    let location = window.location.pathname.split('/')
    console.log('======== crudLocation ======',location,location.includes(pathName))
    console.log('======== pathName ======',pathName)
    console.log('======== crudoldRecord ======',oldRecord)
    console.log('======== crudnewRecord ======',newRecord)
    if(!location.includes(pathName)){
        if(oldRecord){
            return [newRecord._id, ...oldRecord]
        }
        else{
            return [newRecord._id]
        }
    }
    else{
        return oldRecord
    }

    
    
}

export const recordAfterUpdate = (oldData, newData) => {
    console.log('oldData', oldData)
    let newArray = [];
    oldData.map(Item => {
        if (Item._id === newData._id) {
            newArray.push(newData)
        } else {
            newArray.push(Item);
        }
        return Item
    })
    return newArray;
}


export const recordAfterDeleted = (oldData, newData) => {
    let newArray = [];
    oldData && oldData.length > 0 && oldData.map(Item => {
        if (Item._id !== newData._id) {
            newArray.push(Item);
        }
        return Item
    })
    return newArray;
}

export const changeDataStructure = (oldRecord) => {
    console.log('oldRecord', oldRecord)
    oldRecord.forEach(item => {
        item.laborData = [{
            'laborCharges': item.labor,
            'laborAmount': item.laborAmount
        }]
        item.otherCharges = [{
            'otherCharges': item.otherChanges,
            'otherChargesAmount': item.otherChangesAmount
        }]
        item.dataTotal = [{
            'dataTotal': item.grandTotal,

        }]
    })
}


export const updateProfile = (oldData, newData) =>{
    console.log('=updateProfile',oldData)
    oldData.fullName = newData.fullName
    oldData.profileImageUrl = newData.profileImageUrl
    oldData.phoneNumber = newData.phoneNumber

    return oldData
}
export const updateProfileStatus = (oldData, newData) =>{
   
    oldData.pharmacyStatus = newData.pharmacyStatus
    

    return oldData
}
