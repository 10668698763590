import store from '../store';

export const SocketResponseState = (type, payload, recordName,pathName, showNotification) => {
    
    store.dispatch({
        type: `${type}_SUCCESS`,
        payload: payload,
        recordName:recordName,
        pathName : pathName,
        showNotification : showNotification
    });

}
