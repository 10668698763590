
import moment from 'moment'
const INIT_STATE = {
    dashboardData: [{
        totalQuotations : 780,
        totalOrders : 280,
        inProgressOrders : 500,
        completeOrders : 500
    }],
    weeklyAnalysis : [],
    textMessage : [],
    success : false,
    error : false,
    fetchingLoader : false,
    revenueOverview : []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'gettingPharmacyDashboard_START':{
            return {
                ...state,
                fetchingLoader:true,
                error : false,
                success : false,
            };
        }

        case 'getDashboardOverview_SUCCESS':{
            return {
                ...state,
                dashboardData: [...action.payload.Record] ,
                fetchingLoader:false,
                error : false,
                success : true,
            };
        }
        case 'getDashboardOverview_FAILURE':{
            return {
                ...state,
                textMessage: 'could not fetch data due to api failure' ,
                error:true,
                success : false
            };
        }
        case 'getWeeklyOverview_SUCCESS':{
            let data = action.payload.Record.map(item=>{
                if(item.name !== ''){
                    item.name = moment(item.name).format('dddd')
                }
                return item
                
            })
            return {
                ...state,
                weeklyAnalysis: data ,
                fetchingLoader:false
            };
        }
        case 'getRevenueDashboard_SUCCESS':{
            return {
                ...state,
                revenueOverview: action.payload.Record ,
                
            };
        }
        case 'reset-admin-dashboard' :{
            return{
                ...state,
                error : false,
                success : false,
                fetchingLoader : false
            }
        }

        


        
        default:
            return state;
    }
}
