import React from "react";
import Widget from "components/Widget";
import CategoryList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import TableAction from 'components/Table/Actions'
import Layout from 'components/LayoutForPortal'
import SearchBox from 'components/Filters/SearchBox'
import AddNewCategoryForm from './components/AddNewCategory'
import EditNewCategory from './components/EditNewCategory'
import DeleteConfirm from 'components/Modal/DeleteConfirm';
import { useDispatch,useSelector } from 'react-redux'
import { intlPlaceholder } from 'util/intlPlaceholder'
const Index = () => {

    const curdProps = useSelector(state => state.CrudR)
    const { Edit_Category_Modal } = curdProps

    
    const dispatch = useDispatch()
    const columns = [
        {
            title: <IntlMessages id="category.no" />,
            render: (record) => <span>{record.categoryId}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="category.categoryName" />,
            render: (record) => <span>{record.name}</span>,
            key: "categoryName"
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            key: "action",
            width : '50px'
        },

    ];

    const renderActions = (record) => {
        return <div className="gx-d-flex gx-flex-column">
            <span className="action-link first gx-link" onClick={() => {
                dispatch({

                    type: 'Show_Modal',
                    payload: record,
                    ModalName: "Edit_Category_Modal"
                })
            }}> <IntlMessages id="category.actions.edit" /></span>
           
            <DeleteConfirm
                type="deleteCategory"
                recordName={'categoryList'}
                method="DELETE"
                selectedItem={record}
                apiRequest="DeleteRecord"
                buttonName="Yes"
                cancelText={intlPlaceholder('confirmationModal.cancel')} 
                oKText={intlPlaceholder('confirmationModal.yes')}
                title={intlPlaceholder('category.delete.message')} 
                content=""
                Icon={<span style={{ fontSize: '14px' }}><IntlMessages id="category.actions.delete" /></span>}
            />
        </div>
    }


    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold"
                        style={{ color: "#14244E" }}>
                        <IntlMessages id="category.pageTitle" />
                    </h4>
                }
                extra={
                    <div className="gx-d-flex">
                        <div style={{ width: '220px', marginRight: '12px' }}>
                            <SearchBox recordName="categoryList" placeholder={intlPlaceholder('category.filter.categoryName')} apiName="getCategory" requestType="FetchRecord" />
                        </div>
                        <AddNewCategoryForm />
                    </div>
                }
            >

                <CategoryList recordName="categoryList"  apiName="getCategory" columns={columns} />
            </Widget>
           {Edit_Category_Modal && <EditNewCategory />}
        </Layout>
    );
};

export default Index;