import React from 'react'
import DashboardData from 'components/Dashboard/Employee'
import Layout from 'components/LayoutForPortal'
const Index = () => {
    return (
        <Layout>
            <DashboardData />
        </Layout>
    )
}

export default Index;