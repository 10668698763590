//generalAnalytics index
import React, { useEffect } from 'react'

import ChartCard from 'components/ChartCard'

import { Col, message, Row, Spin } from 'antd'

import WeeklyAnalysis from './components/WeeklyAnalysis'

import NewOrders from './components/NewOrders'
import RecentActivities from 'components/ActivitiesList'
import { DataGetAction } from 'redux/actions/CommonHttp'
import { useDispatch, useSelector } from 'react-redux'
import IntlMessages from "util/IntlMessages"
const Index = () => {

    const dispatch = useDispatch();
    const adminProps = useSelector(state => state.adminDashboard)
    const { dashboardData, fetchingLoader, weeklyAnalysis, textMessage, error } = adminProps

   
     const fetchAdminDashboard = () => {
        dispatch(DataGetAction('getPharmacyDashboardOverview', 'getDashboardOverview', '', 'gettingPharmacyDashboard'));
        dispatch(DataGetAction('getPharmacyWeeklyOverview', 'getWeeklyOverview', '', 'gettingWeeklyOverview'));
    }
    useEffect(fetchAdminDashboard, [])


    const Data = [
        { name: 'Page A', price: 200 },
        { name: 'Page B', price: 1200 },
        { name: 'Page C', price: 600 },
        { name: 'Page D', price: 1600 },
        { name: 'Page D', price: 1000 },
        { name: 'Page H', price: 2260 },
        { name: 'Page K', price: 800 },
    ];
    // const analysis = [
    //     { name: '', totalOrders: 200, totalSales: 200,completedOrders: 100},
    //     { name: 'mon', totalOrders: 400, totalSales: 200,completedOrders: 100},
    //     { name: 'tue', totalOrders: 150, totalSales: 200,completedOrders: 100 },
    //     { name: 'wed', totalOrders: 400, totalSales: 200,completedOrders: 100},
    //     { name: 'thu', totalOrders: 1000, totalSales: 200,completedOrders: 100},
    //     { name: 'fri', totalOrders: 400, totalSales: 200,completedOrders: 100},
    //     { name: 'sat', totalOrders: 1200, totalSales: 200,completedOrders: 100},
    //     { name: 'sun', totalOrders: 1000, totalSales: 200,completedOrders: 100},
      
    //     { name: '', totalOrders: 500, totalSales: 200,completedOrders: 100, delivered: 180 },
    // ];

    const recentActivities = [
        {
            id: 1,
            name: 'Mila Alba',
            desc: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti, quae?',
            url: "https://picsum.photos/50",
        },
        {
            id: 2,
            name: 'Bob Builder',
            desc: 'Lorem ipsum dolor sit amet.',
            url: "https://picsum.photos/50",
        },
        {
            id: 3,
            name: 'Tom Moody',
            desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            url: "https://picsum.photos/50",
        },
        {
            id: 4,
            name: 'Norman Dolphi',
            desc: 'Norman Dolphi is looking for a house in New Jersy, USA',
            url: 'https://picsum.photos/50',
        },
        {
            id: 5,
            name: 'Bob Builder',
            desc: 'Lorem ipsum dolor sit amet.',
            url: "https://picsum.photos/50",
        },
        {
            id: 6,
            name: 'Tom Moody',
            desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            url: "https://picsum.photos/50",
        },
        {
            id: 7,
            name: 'Norman Dolphi',
            desc: 'Norman Dolphi is looking for a house in New Jersy, USA',
            url: 'https://picsum.photos/50',
        }
    ];

    if (error) {
        message.error(textMessage)
    }


    return (
        <>

            {fetchingLoader ?
                <Spin size="large" color="blue" />
                :
                <>
                    <Row >
                        <Col xl={6} lg={6} md={12} sm={24} xs={24}>

                            <ChartCard count={(dashboardData.length && dashboardData[0].totalProduct) || 0} desc={<IntlMessages id="dashboard.products" />} Data={Data} bgColor={'#FCEAF4'} borderColor={'#F8C8E3'} />
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                            <ChartCard count={(dashboardData.length && dashboardData[0].totalOrder) || 0} desc={<IntlMessages id="dashboard.totalOrders" />} Data={Data} bgColor={'#E3F7D7'} borderColor={'#B8E986'} />
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                            <ChartCard count={(dashboardData.length && dashboardData[0].pendingOrder) || 0} desc={<IntlMessages id="dashboard.pendingOrders" />} Data={Data} bgColor={'#F3ECF9'} borderColor={'#C39FDF'} />
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                            <ChartCard count={(dashboardData.length && dashboardData[0].completedOrder) || 0} desc={<IntlMessages id="dashboard.completedOrders" />} Data={Data} bgColor={'#e5eef5'} borderColor={'#6aafe6'} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={17} lg={17} md={12} sm={24} xs={24}>


                            <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <NewOrders />
                                </Col>
                            </Row>




                            <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                                    <WeeklyAnalysis data={weeklyAnalysis} title={<IntlMessages id="dashboard.weeklyAnalysis" />} width={'100%'} height={180} axisX={'name'} axisY={'totalOrder'}
                                        showNumber={4}
                                        showYAxis={true} startColor={'#D3D6F5'} endcolor={'#D3D6F5'} strokeColor={'#6974E3'} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={7} lg={7} md={12} sm={24} xs={24}>
                            <div className="recent-activity-container">
                                <RecentActivities recentActivity={recentActivities} showImage={false} title={<IntlMessages id="dashboard.notifications" />} />
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}

export default Index
