import React, { useState, useEffect } from "react";
import { Layout, Popover, Switch } from "antd";
import { toggleCollapsedSideNav, onNavStyleChange, switchLanguage } from "redux/actions/Setting";
import UserInfo from "components/UserInfo";
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE, THEME_TYPE_LITE, NAV_STYLE_NO_HEADER_MINI_SIDEBAR } from "constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "redux/actions/CommonHttp";
import IntlMessages from 'util/IntlMessages'
import languageData from "./languageData";
import { DataGetAction } from "redux/actions/CommonHttp";
const { Header } = Layout;





const Topbar = ({ transparent }) => {




  const dispatch = useDispatch();

  const fetchProfileData = () => {
    dispatch(DataGetAction('getProfile', 'getProfile', '', 'gettingProfile'));
  }

  useEffect(fetchProfileData, []);

  const { width, navCollapsed, navStyle, themeType } = useSelector(({ settings }) => settings);
  const locale = useSelector(({ settings }) => settings.locale);

  const authProps = useSelector(state => state.auth)
  const { settingsData } = authProps
  //const workingHour = settingsData && settingsData.workingHour && settingsData.workingHour.length && settingsData.workingHour

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { pharmacyStatus, pharmacyName } = userInfo || {}


  //let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];




  const [showOpen, setShowOpen] = useState(pharmacyStatus === 'online' ? true : false)
  //const [isSwitchDisable, setIsSwitchDisable] = useState( false)


  // const setInitialValue = () => {
  //   if (workingHour) {
  //     let day = days[new Date().getDay()];
  //     let currentDayTiming = workingHour.filter(item => item.day === day)
  //     let currentTime = currentDayTiming && currentDayTiming[0].time

  //     if (currentTime === 'off') {
  //       setShowOpen(false)
  //       setIsSwitchDisable(true)
  //     }
  //     else if (currentTime === '24'){
  //       setShowOpen(true)
  //       setIsSwitchDisable(true)
  //     }
  //     else if (currentTime === "custom"){

  //     }
  //     console.log('===========currentTime=========', currentDayTiming)
  //   }
  // }


  // useEffect(setInitialValue, [settingsData])

  const languageMenu = () => (

    <ul className="gx-sub-popover">
      {languageData.map(language =>
        <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
          dispatch(switchLanguage(language))
        }>
          <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
          <span className="gx-language-text">{language.name}</span>
        </li>
      )}
    </ul>
  );
  function onChange(checked) {
    console.log(`switch to ${checked}`);
    setShowOpen(!showOpen)
    if (!showOpen) {
      dispatch(DataRequestAction('PUT', 'pharmacyOpenClose', 'pharmacyOpenClose', { pharmacyStatus: 'online' }, 'StartSpinner', '', ''))
    }
    else {
      dispatch(DataRequestAction('PUT', 'pharmacyOpenClose', 'pharmacyOpenClose', { pharmacyStatus: 'offline' }, 'StartSpinner', '', ''))
    }
  }
  return (
    <Header >
      <div className={`gx-d-flex gx-justify-content-between gx-align-items-center gx-w-100 header-container`}>
        <div className="gx-d-flex gx-align-items-center gx-w-100-sm first-header-content">
          {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
            <div className="gx-linebar gx-mr-2">
              <i className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div> : null}

          {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar gx-d-none gx-d-md-block">
            <i
              className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-black' : ''}`}
              onClick={() => {
                if (navStyle === NAV_STYLE_DRAWER) {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                } else if (navStyle === NAV_STYLE_FIXED) {
                  dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR))
                } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                } else {
                  dispatch(onNavStyleChange(NAV_STYLE_FIXED))
                }
              }}
            />
          </div> : null}

          <h5 className="gx-ml-3 gx-text-capitalize"> <i className="icon icon-company " style={{ fontSize: '18px' }}></i>  {pharmacyName}</h5>

        </div>

        <div className="gx-header-notifications gx-d-flex gx-justify-content-between gx-align-items-center  gx-p-2 gx-p-sm-0 gx-w-100-sm" style={{gap : '10px'}}>

          {userInfo && userInfo.role && userInfo.role === 'pharmacy' && <li>
            {showOpen ? <small className="gx-mr-2"><IntlMessages id="topBar.open" /></small> : <small className="gx-mr-2"><IntlMessages id="topBar.close" /></small>}
            <Switch
              defaultChecked={showOpen}
              //checked={showOpen}
              //disabled={isSwitchDisable}
              onChange={onChange}
              size="small" />
          </li>}

          <li className="gx-language">
            <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
              content={languageMenu()} trigger="hover">
              <span className="gx-pointer gx-flex-row gx-align-items-center">
                <i className={`flag flag-24 flag-${locale.icon}`} />
                <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                <i className="icon icon-chevron-down gx-pl-2" />
              </span>
            </Popover>

          </li>


          <div className="gx-user-nav gx-align-items-center gx-d-flex">

            <UserInfo />
          </div>
        </div>
      </div>

    </Header>
  );
};

export default Topbar;
