import React, { useEffect } from 'react'
import { Table, message, notification } from 'antd';
import { DataGetAction } from 'redux/actions/CommonHttp'
import { useDispatch, useSelector } from 'react-redux'
import audioSound from 'assets/synthesize.mp3'


const Index = ({
    columns,
    scroll,
    pagination,
    apiName,
    callOnCondition,
    customQuery = {},
    recordName,
    tempRecord,
    styleName,
    query = "all"
}) => {

    const dispatch = useDispatch()
    const CurdProps = useSelector(state => state.CrudR)



    const fetchList = () => {
        dispatch(DataGetAction(apiName, 'FetchRecord', { query: query, ...customQuery }, 'StartSpinner', '', recordName))
        dispatch({
            type: 'RemoveBadges',
            recordName: `${recordName}`
        })
    }

    useEffect(fetchList, [callOnCondition])


    const { success, error, DeleteError, showNotification } = CurdProps;
    console.log('======showNotification=======', showNotification)

    const openNotificationWithIcon = type => {
        notification[type]({
            message: 'New Order Received',
            description:
                'New Order has been received.Please go to order menu to check the details'
        });

    };
    const playSound = (audioSound) => {
        const audio = new Audio(audioSound);
        audio.play();
    }
    if (success) {
        if (CurdProps.message !== '') {


            if (showNotification) {
                openNotificationWithIcon('success')
                playSound(audioSound)
                dispatch({
                    type : 'ResetAll_State'
                })
            }
            else {
                message.success(CurdProps.message)
            }

        }
        dispatch({
            type: 'ResetAll_State'
        })
    }

    if (error) {
        if (CurdProps.message !== '') {
            message.error(CurdProps.message)
        }
        dispatch({
            type: 'ResetAll_State'
        })
    }
    if (DeleteError) {
        message.error(CurdProps.message)
        dispatch({
            type: 'ResetAll_State'
        })
    }


    return <Table
        className={`BasicTable gx-table-responsive ${styleName}`}
        columns={columns}
        dataSource={tempRecord ? tempRecord : CurdProps[recordName]}
        scroll={scroll}
        pagination={pagination}
    />
}

export default Index
