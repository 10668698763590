import React from 'react'
import { useSelector } from 'react-redux'
import FormModal from "components/Modal/EditFormModal";
import IntlMessages from 'util/IntlMessages'
import EmployeeForm from './EmployeeForm'


const EditProduct = () => {



    
    const curdProps = useSelector(state => state.CrudR)
    const { Edit_Employee_Modal, Loader, initialValues } = curdProps


   

  
    return (
        <div>


            <FormModal
                modalTitle={<p className="gx-text-primary"> <IntlMessages id="employee.UpdateEmployeeModal.title" /> </p>}
                basicButtonTitle="Reset Password"
                submitButtonText={<IntlMessages id="employee.UpdateEmployeeModal.update" />}
                cancelButtonText={<IntlMessages id="employee.UpdateEmployeeModal.cancel" />}
                editApiRequest="EditRecord"
                apiLoader="StartSpinner"
                editApiMethod="PUT"
                editApiName="editEmployee"
                ModalName="Edit_Employee_Modal"
                confirmLoading={Loader}
                ModalType="Hide_Modal"
                visible={Edit_Employee_Modal ? Edit_Employee_Modal : false}
                buttonStyleClass="gx-buttonStyleClass"
                isShowButton={false}
                initialValues={initialValues}
                width={600}
                className="doctor-profile-modal"
                recordName="employeeList"
            >
              
                 <EmployeeForm action='edit'  />
            </FormModal>
        </div>

    )
}

export default EditProduct
