import React from "react";
import Widget from "components/Widget";
import UserList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import TableAction from 'components/Table/Actions'
import Layout from 'components/LayoutForPortal'
import SearchBox from 'components/Filters/SearchBox'
import { useDispatch } from 'react-redux'
import { DataRequestAction } from 'redux/actions/CommonHttp';
import { renderStatus } from 'utils/commonUseFunction'
import { intlPlaceholder } from 'util/intlPlaceholder'
import AddNewEmployee from './components/AddNewEmployee'
import EditEmployee from './components/EditEmployee'
import DeleteConfirm from 'components/Modal/DeleteConfirm';
import ResetPasswordModal from './components/ResetPasswordModal'
const Index = () => {

    const dispatch = useDispatch()





    const columns = [
        {
            title: <IntlMessages id="employee.id" />,
            render: (record) => <span>{record.userId}</span>,
            key: "id",
        },
        {
            title: <IntlMessages id="employee.name" />,
            render: (record) => <span>{record.fullName}</span>,
            key: "fullName"
        },
        {
            title: <IntlMessages id="employee.email" />,
            render: (record) => <span>{record.email}</span>,
            key: "email"
        },
        {
            title: <IntlMessages id="employee.phoneNo" />,
            render: (record) => <span>{record.phoneNumber}</span>,
            key: "phone"
        },

       
        {
            title: <IntlMessages id="employee.status" />,
            render: (record) => <span className="gx-text-capitalize">{renderStatus(record.status)}</span>,
            key: "status"
        },

       
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            key: "action"
        },
    ];


    const changeUserStatus = (userId, status) => {
        dispatch(DataRequestAction('PUT', 'updateEmployeeStatus', 'EditRecord', { userId: userId, status: status }, 'updatingEmployeeStatus', false,"employeeList"  ));
    }

    const openEditEmployee = (record) => {
        
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Edit_Employee_Modal"
        })
    }
    const changePasswordModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Change_Password_Modal"
        })
    }
    const renderActions = (record) => {
        return <div className="gx-d-flex gx-flex-column">
            <span style={{ cursor: 'pointer' }} onClick={() => openEditEmployee(record)}><IntlMessages id="employee.actions.edit" /></span>
            <DeleteConfirm
                type="deleteEmployee"
                recordName={'employeeList'}
                method="DELETE"
                selectedItem={record}
                apiRequest="DeleteRecord"
                cancelText={intlPlaceholder('confirmationModal.cancel')} 
                oKText={intlPlaceholder('confirmationModal.yes')} 
                title={intlPlaceholder('employee.actions.delete_text')} 
                content={intlPlaceholder('employee.actions.delete_content')} 
                Icon={<span style={{ fontSize: '14px' }}><IntlMessages id="employee.actions.delete" /></span>}
            />
            {(record.status === "active") && <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'block')}><IntlMessages id="employee.actions.block" /></span>}
            {(record.status === "block") && <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'active')}><IntlMessages id="employee.actions.unBlock" /></span>}
            <span style={{ cursor: 'pointer' }} onClick={() => { changePasswordModal(record) }}><IntlMessages id="employee.actions.resetPassword" /></span>
        </div>
    }




    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold"
                        style={{ color: "#14244E" }}>
                        <IntlMessages id="employee.pageTitle" />
                    </h4>
                }
                extra={<div className="gx-d-flex gx-mb-0 filters-container" style={{ flexWrap: 'wrap' }}>
                    
                    <div style={{ width: '220px', marginRight : '10px' }}>
                        <SearchBox placeholder={intlPlaceholder('employee.filter.nameEmailPlatform')}recordName="employeeList"   apiName="getEmployee" requestType="FetchRecord" />
                    </div>
                    <AddNewEmployee />
                </div>}
            >

                <UserList 
                    scroll={{x : 1400}}
                    recordName="employeeList"  
                    apiName="getEmployee" 
                    columns={columns} />
                
            </Widget>
            <EditEmployee />
            <ResetPasswordModal/>
        </Layout>
    );
};

export default Index;