
const MenuList = {

    "pharmacy": [{
        "title": 'leftMenu.dashboard',
        "url": "/dashboard",
        "icon": "dasbhoard",
        "top": '4px',
        "permissionKey": "dashboard",
        "key": "dashboard"
    },

    {
        "title": 'leftMenu.employees',
        "url": "/employees",
        "icon": 'wall',
        "top": '4px',
        "permissionKey": "employees",
        "key": "employees"
    },
    {
        "title": 'leftMenu.product',
        "url": "/product",
        "icon": 'feedback',
        "top": '4px',
        "permissionKey": "tasks",
        "key": "product"
    },

    {
        "title": "leftMenu.order",
        "icon": "product-list",
        "permissionKey": "products",
        "menuBadge": true,
        "key": "order",
        "subMenu": [{
            "title": "leftMenu.order.newOrder",
            "url": "/order/newOrder",
            "icon": "",
            "key": "order/newOrder",
            "badgeKey": "newOrderList_badge",
            badges: true,
        },
        {
            "title": "leftMenu.order.quotationSend",
            "url": "/order/quotationSend",
            "icon": "",
            "key": "order/quotationSend",
        },
        {
            "title": "leftMenu.order.requestedOrder",
            "url": "/order/requestedOrder",
            "icon": "",
            "key": "order/requestedOrder",
            "badgeKey": "requestedOrderList_badge",
            badges: true,
        },
        {
            "title": "leftMenu.order.inProcess",
            "url": "/order/inProcess",
            "icon": "",
            "key": "order/inProcess",
        },
        {
            "title": "leftMenu.order.completed",
            "url": "/order/completed",
            "icon": "",
            "key": "order/completed",
        },
        {
            "title": "leftMenu.order.cancelled",
            "url": "/order/cancelled",
            "icon": "",
            "key": "order/cancelled",
        },
        ]
    },
    {
        "title": 'leftMenu.setting',
        "url": "/settings",
        "icon": "setting",
        "top": '4px',
        "permissionKey": "tasks",
        "key": "setting"
    },


    ],
    "employee": [
        {
            "title": "leftMenu.order.newOrder",
            "url": "/order/newOrder",
            "icon": "",
            "key": "order/newOrder",
            "badgeKey": "newOrderList_badge",
            badges: true,

        },
        {
            "title": "leftMenu.order.quotationSend",
            "url": "/order/quotationSend",
            "icon": "",
            "key": "order/quotationSend",
            "badgeKey": "quotationSentList_badge",
        },
        {
            "title": "leftMenu.order.requestedOrder",
            "url": "/order/requestedOrder",
            "icon": "",
            "key": "order/requestedOrder",
            "badgeKey": "requestedOrderList_badge",
            badges: true,
        },
        {
            "title": "leftMenu.order.inProcess",
            "url": "/order/inProcess",
            "icon": "",
            "key": "order/inProcess",
            "badgeKey": "inProcessOrderList_badge",

        },
        {
            "title": "leftMenu.order.completed",
            "url": "/order/completed",
            "icon": "",
            "key": "order/completed",
            "badgeKey": "completedOrderList_badge",

        },
        {
            "title": "leftMenu.order.cancelled",
            "url": "/order/cancelled",
            "icon": "",
            "key": "order/cancelled",
            "badgeKey": "cancelledOrderList_badge",

        },
        {
            "title": 'leftMenu.setting',
            "url": "/settings",
            "icon": "",
            "top": '4px',
            "permissionKey": "tasks",
            "key": "settings"
        },
    ],
    "customer": [
        {
            "title": "leftMenu.customerOrder",
            "url": "customerOrder",
            "icon": "",
            "key": "customerOrder",
        },

    ]
}

export default MenuList;