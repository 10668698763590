const SocketResponseState = require('../redux/actions/socket').SocketResponseState;
const io = require("socket.io-client");
const Socket = (function () {
    let instance;
    function createInstance(userId, romeId, test="test") {
        console.log("=====disconnect test ===",test);
        let socket = io(process.env.REACT_APP_ROOT_URL, { query: `clientId=${userId}&roomId=${romeId}` });
        socket.on("connect", function (err, data) {
            console.log("========= socket ============ Connection Created", err, data);
        });

        socket.on("newQuotationCreated", function (data) {
            console.log("========= newQuotationCreated ============" , data);
            SocketResponseState('AddRecord', { Record: data , message : '' }, 'newOrderList', 'newOrder',true  );
            SocketResponseState('AddRecord', { Record: data , message : '' }, 'dashboardOrders', 'newOrder'  );
        })
        

        socket.on("newQuotationSent", function (data) {
            console.log("========= newQuotationSent ============" , data);
            SocketResponseState('DeleteRecord', { Record: data , message : ''}, 'newOrderList');
            SocketResponseState('AddRecord', { Record: data,message : `Order no ${data.orderId && data.orderId} quotation sent sentSuccessfully!` }, 'quotationSentList','quotationSend' );
        })

        socket.on("cancelOrder", function (data) {

            console.log("========= cancelOrder ============" , data);


            SocketResponseState('DeleteRecord', { Record: data , message : '' }, 'quotationSentList');
            SocketResponseState('AddRecord', { Record: data,message : `Order no ${data.orderId && data.orderId} has been cancelled Successfully!` }, 'cancelledOrderList', 'cancelled');
        })


        socket.on("createNewOrder", function (data) {
            console.log('=========createNewOrder========',data)
            SocketResponseState('DeleteRecord', { Record: data, message : '' }, 'quotationSentList');
            SocketResponseState('AddRecord', { Record: data, message : `Order no ${data.orderId && data.orderId} request has been sent Successfully!` }, 'requestedOrderList', 'requestedOrder',true);
            SocketResponseState('AddRecord', { Record: data, message : `` }, 'dashboardOrders', 'requestedOrder');
        })


        socket.on("completeOrder", function (data) {
            console.log("=========== completeOrder =======", data);
            SocketResponseState('DeleteRecord', { Record: data, message : '' }, 'requestedOrderList');
            SocketResponseState('AddRecord', { Record: data, message : `Order no ${data.orderId && data.orderId}  has been completed Successfully!` }, 'completedOrderList','completed');
        })

        socket.on("riderPickup", function (data) {
            console.log("=========== riderPickup =======", data);

            SocketResponseState('DeleteRecord', { Record: data , message : ''}, 'requestedOrderList',false);
            SocketResponseState('AddRecord', { Record: data, message : `Order no ${data.orderId && data.orderId} is in process now Successfully!`}, 'inProcessOrderList','inProcess',false);
        })

        socket.on("completedOrderByCustomer", function (data) {

            console.log("=========== completedOrderByCustomer =======", data);



            SocketResponseState('DeleteRecord', { Record: data, message : '' }, 'inProcessOrderList');
            SocketResponseState('AddRecord', { Record: data, message : `Order no ${data.orderId && data.orderId} has been completed Successfully!` }, 'completedOrderList','completed');
        })

        socket.on("newProduct", function (data) {
            console.log("======== newProduct ============", data)
            SocketResponseState('AddRecord', { Record: data, message : 'New Product Added Successfully!' }, 'productList');
        })
        socket.on("editProduct", function (data) {
            console.log("======== editProduct ============", data)
            SocketResponseState('EditRecord', { Record: data , message : 'Product Updated Successfully!'}, 'productList');
        })

        socket.on("updateProduct", function (data) {
            console.log("======== updateProduct ============", data)
            SocketResponseState('EditRecord', { Record: data, message : `Product ${data.productName && data.productName} has been Updated Successfully!` }, 'productList');
        })


        socket.on("newActivityCreated", function (data) {
            console.log("======== newActivityCreated ============", data)
            SocketResponseState('newActivityCreated', data)
        })

        socket.on("NewLeadReceived", function (data) {
            SocketResponseState('NewLeadReceived', data)
        })
        socket.on("disconnect", () => {
            console.log("==========",socket.connected); // false
          });

        function onConnect(userId) {
            return new Promise((resolve, reject) => {
                socket.emit("connect", { "id": userId });
                resolve();
            });
        }
        function socketDisconnet() {
            return new Promise((resolve, reject) => {
                console.log("========= Front Socket reload =========")
                sessionStorage.setItem('is_reload',true);
                socket.emit("disconnect", "test this function call");
                resolve();
            });
        }
        return {
            onConnect,
            socketDisconnet,
        };
    }
    return {
        getInstance: function (userId, roomId) {
            if (!instance) {
                instance = createInstance(userId, roomId);
            }
            return instance;
        }
    };
})();
module.exports = {
    Socket: Socket
};