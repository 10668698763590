import React from 'react'
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import FormModal from "components/Modal/FormModal";
import IntlMessages from 'util/IntlMessages'
import EmployeeForm from './EmployeeForm'


const AddNewProductForm = () => {



    const dispatch = useDispatch()
    const curdProps = useSelector(state => state.CrudR)
    const { Add_Employee_Modal, Loader } = curdProps

    return (
        <div>
            <Button
                className='gx-pt-0'
                type="primary"
                onClick={() => {
                    dispatch({
                        type: 'Show_Modal',
                        payload: null,
                        ModalName: "Add_Employee_Modal"
                    })
                }}
            >
                <IntlMessages id="employee.addNewButton.label" />
            </Button>
            <FormModal
                modalTitle={<p className="gx-text-primary"> <IntlMessages id="employee.addEmployeeModal.title" /> </p>}
                basicButtonTitle="Reset Password"
                submitButtonText={<IntlMessages id="employee.modal.save" />}
                cancelButtonText={<IntlMessages id="employee.modal.cancel" />}
                apiMethod="POST"
                addApiName="addEmployee"
                addApiRequest="AddRecord"
                apiLoader="StartSpinner"
                ModalName="Add_Employee_Modal"
                confirmLoading={Loader}
                ModalType="Hide_Modal"
                visible={Add_Employee_Modal ? Add_Employee_Modal : false}
                buttonStyleClass="gx-buttonStyleClass"
                isShowButton={false}
                width={600}
                className="doctor-profile-modal"
                recordName="employeeList"
            >
                <EmployeeForm  />
            </FormModal>
        </div>

    )
}

export default AddNewProductForm
