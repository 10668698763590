
//this function takes a camel case string and return a space separated sting
//i-e it converts a string  like 'helloThere' or 'HelloThere' to 'Hello There'
import moment from 'moment'
import React from 'react'
export const camelCaseToNormal = (string) => {
    var text = string;
    var result = text.replace(/([A-Z])/g, " $1");
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}
const styles = {
    btn: {

        display: 'inlineBlock',
        padding: '2px 10px',
        borderRadius: '14px',

    },
    yellow: {
        color: '#937935',
        
    },
    green: {
        color: '#51bda7',
        
    },
    red: {
        color: '#EE3F44',
    
    },
    grey: {
        color: '#9CA9B8',
        

    }
}
export const renderStatus = (status) => {



    if (status === 'active' || status === 'approved') {
        return <span style={{ ...styles.green }}>{status}</span>
    }
    if (status === 'pending') {
        return <span style={{ ...styles.yellow }}>{status}</span>
    }
    if (status === 'completed') {
        return <span style={{ ...styles.grey }}>{status}</span>
    }
    if (status === 'block' || status === 'reject') {
        return <span style={{ ...styles.red }}>{status}</span>
    }
    if (status === 'deleted' || (status === 'removed') ) {
        return <span style={{ ...styles.red }}>{status}</span>
    }

}


export const convertStringToHTML = (string) => {
    let parser = new DOMParser();
    let doc = parser.parseFromString(string, 'text/html');
    doc = doc.body.innerHTML
    doc = JSON.stringify(doc)
    doc = doc.replace(/['"]+/g, '')
    return doc
}

export const renderDate = (date, format = "MMMM Do YYYY") => {

    return moment(date).format(format);
}
export const printConsole = (action, data) => {
    return console.log(`===========${action}=========`, data)
}


// this function is used to sort the days - it takes array of input 
export const sortDays = (
        days, 
        startingDay = 0,
        listArray = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday','sunday'],
        sortingKey = "day"
        ) => {
    const dayOfWeek = startingDay;
    let list = listArray;
    const sortedList = list.slice(dayOfWeek).concat(list.slice(0, dayOfWeek));
    return days && days.sort((a, b) => {
      if (sortedList.indexOf(a[sortingKey]) > sortedList.indexOf(b[sortingKey])) return 1;
      if (sortedList.indexOf(a[sortingKey]) < sortedList.indexOf(b[sortingKey])) return -1;
      return 0;
    });
  };