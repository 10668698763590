import React, { useState } from 'react'
import { Form, Input, Row, Col } from 'antd';
import IntlMessages from 'util/IntlMessages'
import PhoneInput from 'react-phone-input-2'




const EmployeeForm = ({action}) => {

    const [phoneNumber, setPhoneNumber] = useState();

    return (
        <div>

            <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        name="fullName"
                        label={<IntlMessages id="employee.addEmployeeModal.name" />}
                        rules={[
                            {
                                required: true,
                                message: <IntlMessages id="employee.addEmployeeModal.name" />
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>


                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        name="email"
                        className="lower-case-email"
                        label={<IntlMessages id="employee.addEmployeeModal.email" />}
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: <IntlMessages id="employee.addEmployeeModal.email" />
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

            </Row>







            <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    

                    <Form.Item
                                label={<IntlMessages id="employee.addEmployeeModal.phone" />}
                                name="phoneNumber"
                                style={{
                                    marginLeft: '0px',
                                    marginRight: '0px'
                                }}
                                rules={[{ required: true, message: <IntlMessages id="employee.addEmployeeModal.phone" /> }]}
                            >
                                <PhoneInput
                                    inputStyle={{
                                        width: '100%'
                                    }}
                                    country={'fr'}
                                    value={phoneNumber}
                                    onChange={phone => setPhoneNumber({ phone })}
                                />
                            </Form.Item>
                </Col>

               {action !== 'edit' && <Col xl={12} lg={12} md={12} sm={24} xs={24}>

                    <Form.Item
                        label={<IntlMessages id="employee.addEmployeeModal.setPassword" />}
                        name="password"
                        style={{
                            marginLeft: '0px',
                            marginRight: '0px'
                        }}
                        rules={[{ required: true, message: <IntlMessages id="employee.addEmployeeModal.setPassword" /> }]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>}
            </Row>
            {action !== 'edit' &&   <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        name="confirmPassword"
                        label={<IntlMessages id="employee.addEmployeeModal.confirmPassword" />}
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: <IntlMessages id="employee.addEmployeeModal.confirmPassword" />,
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(<IntlMessages id="employee.addEmployeeModal.passwordNotMatchMessage" />);
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>

            </Row>}
            {/* <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        name="productImage"
                        label={<IntlMessages id="employee.addEmployeeModal.productImage" />}
                        
                    >
                       <FileUploader  imageUrl={initialValues && initialValues.productImageUrl} setFileList={setFileList} fileList={fileList} multiple={true} />
                    </Form.Item>
                </Col>
                
            </Row> */}


        </div>
    )
}

export default EmployeeForm




