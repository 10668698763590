import React from 'react'
import Widget from 'components/Widget'
import IntlMessages from 'util/IntlMessages'
import NewOrder from "components/Table/List";
import { camelCaseToNormal } from 'utils/commonUseFunction'
import { useHistory } from 'react-router-dom'
const NewOrders = () => {

    const history = useHistory();
    const columns = [
        {
            title: <IntlMessages id="newOrder.no" />,
            render: (record) => <span>{record.orderId && record.orderId}</span>,
            key: "id",
        },
        
        {
            title: <IntlMessages id="newOrder.customerName" />,
            render: (record) =>  <span className="gx-ml-2">{record.fullName && record.fullName }</span> ,
            key: "doctorName",
        },

        {
            title: <IntlMessages id="newOrder.phone" />,
            render: (record) => <span>{record.phoneNumber && record.phoneNumber}</span>,
            key: "phoneNumber"
        },
        {
            title: <IntlMessages id="newOrder.email" />,
            render: (record) => <span>{record.email}</span>,
            key: "email"
        },
        {
            title: <IntlMessages id="newOrder.status" />,
            render: (record) => <span className="gx-text-primary">{camelCaseToNormal(record.status && record.status)}</span>,
            key: "status"
        },
    ];
    return (
        <div>
            
            <Widget 
                styleName={'fixed-height-table'}
                title={<h6 className="gx-text-grey gx-font-weight-bold"><IntlMessages id="dashboard.newOrders" /></h6>}
                extra={<span className="gx-link" onClick={()=>{history.push('/order/newOrder')}}>View All</span>}
                >
            <NewOrder pagination={{
                    total: 5,
                    pageSize: 5,
                    hideOnSinglePage: true
                }} styleName="borderLessTable" query={'status'} customQuery={{value : 'dashboardOrder'}}  recordName="dashboardOrders" bordered={false} apiName="getOrder" columns={columns} />
            
            </Widget>
            
        </div>
    )
}

export default NewOrders
